<!-- 考试大类 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
@import "~@/assets/css/account.css";
</style>
<style type="text/css" scoped>
:deep(.el-form-item__content) {
  line-height: inherit;
}

/deep/.el-form-item {
  margin-bottom: 0;
}

/deep/.el-pagination__jump {
  margin-left: 0;
  margin-right: 10px;
}
.queryForm .el-select,.queryForm .el-input{
  width: 150px !important;
}
.check-distri {
  line-height: 32px;
}
.el-tag{
  cursor: pointer;
}
/deep/ .el-dialog__header {
  background: rgb(197 197 197 / 40%) !important;
  padding: 20px;
}

</style>
<script>
import Layout from "@/views/layouts/main";
import {
  listPage,
  findUser,
  info,
  removeUser,
  removeFile,
  delInformation,
  memberList, forward
} from "@/api/admin/information/informationCollaboration.js";
import {
  getcode,
  userCodeExpired,
  sendUserPhoneCode
} from "@/api/index.js"
import $ from "jquery";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,

  },
  data() {
    return {
      wjlx:[
          '公告文件','考试数据','数据样张'
      ],
      query: {
        keyword: '',
        yxzt: '',
      },
      uploadModule: 'Information',
      uploadLimit:{},
      items: [{
        text: "用户首页",
        href: "/admin"
      },
        {
          text: "基础大类",
          active: true
        }
      ],
      exportTitle: "考试类别导出",
      showmodal: false,
      dialogTitle: "添加考试类别",
      module: "KSDL",
      tableList: [],
      allDataList: [],
      addForm: {
        lbbm: "",
        lbmc: "",
        lbsx: "资格类",
        qyzt: true,
        pxh: 0,
      },
      pageData: {
        pageNum: 1,
        pageSize: 50,
        total: 0
      },
      isIndeterminate: false,
      checkList: [],
      memberShow: false,
      memberList: [],
      memberSearch: {},
      isShow: false,
      imgCode: {
        uuid: "",
        img: "",
        code: "",
      },
      verification: "",
      committingPhoneCode: false,
      phoneCode: '',
      wjdz: '',
      phoneCodeShow: false,
    };
  },
  methods: {
    // 删除
    deleteItem(obj) {
      var checkboxes = document.querySelectorAll('.checkRow');
      let list = []
      checkboxes.forEach(function(checkbox) {
        if(checkbox.checked) {
          list.push(checkbox.value)
        }
      });
      if(obj.infoId && list.length) {
        return;
      }
      if(obj.infoId) {
        this.$confirm(`是否确认删除【${obj.wjbt}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delInformation( [obj.infoId]).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: '删除成功',
              });
              this.getList();
            }
          });
        }).catch(() => {

        });
      }else {
        this.$confirm(`是否确认批量删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delInformation(list).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: '删除成功',
              });
              this.getList();
            }
          });
        }).catch(() => {

        });
      }
    },
    // 获取数据列表
    getList() {
      listPage({...this.query, ...this.pageData}).then(res => {
        if (res.status) {
          this.tableList = res.data
          this.pageData.total = res.total
        }
      })
    },

    editItem(data, flag) {
      this.addForm = {}
      this.showmodal = true
      this.dialogTitle = "查看信息协同"
      info(data.infoId).then(res => {
        if(res.status) {
          this.addForm = res.data
          this.addForm.flag = flag
        }
      })
    },
    submit() {
      forward(this.addForm).then(res => {
          if (res.status) {
            this.showmodal = false;
            this.getList();
            this.$message({
              type: "success",
              message: '转发成功',
            });
          }
      });
    },

    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val
      this.getList();
    },

    handleUserClose(data) {
      if(!data.memberId){
        this.addForm.userList = this.addForm.userList.filter(f => { return f.sjrzh!=data.sjrzh})
        return;
      }
      this.$confirm(`是否确认删除”${data.sjr}“?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeUser(this.addForm.infoId, data.memberId).then((res) => {
          if (res.status) {
            this.addForm.userList = this.addForm.userList.filter(f => { return f.sjrzh!=data.sjrzh})
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleFileClose(data) {
      this.$confirm(`是否确认删除文件”${data.wjm}“?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeFile(this.addForm.infoId, data.fileId).then((res) => {
          if (res.status) {
            this.addForm.fileList = this.addForm.fileList.filter(f => { return f.fileId!=data.fileId})
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    addUser(){
      if(!this.addForm.yddhStr) {
        return;
      }
      findUser({yddh: this.addForm.yddhStr}).then(res => {
        if(res.status) {
          res.data = res.data.map(m => {
            return {infoId: this.addForm.infoId||null, sjrzh: m.yhzh, sjr: m.xm, yddh: m.yddh}
          })
          this.addForm.userList = [...this.addForm.userList||[], ...res.data]
          this.addForm.userList = this.addForm.userList.filter((item, index, self) =>
                  index === self.findIndex(t => (
                      t.sjrzh === item.sjrzh
                  ))
          );
          this.$forceUpdate()
        }
      })
    },
    isDateExpired(dateString) {
      // 将字符串日期转换为Date对象
      // 假设dateString的格式为"YYYY-MM-DD"
      const dateParts = dateString.split('-');
      const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]); // 注意月份是从0开始的

      // 获取当前日期（不包含时间部分）
      const now = new Date();
      now.setHours(0, 0, 0, 0); // 将当前时间设置为当天的开始

      // 比较两个日期
      return date >= now;
    },
    // 全选
    checkAll() {
      var checkboxes = document.querySelectorAll('.checkRow');
      checkboxes.forEach(function(checkbox) {
        checkbox.checked = document.getElementById('all').checked;
      });
    },
    openMember(item){
      this.memberShow = true;
      this.memberSearch.infoId = item.infoId
      this.memberSearch.keyword = ''
      memberList(this.memberSearch).then((res) => {
        if (res.status) {
          this.memberList = res.data
        }
      });
    },
    searchMember() {
      memberList(this.memberSearch).then((res) => {
        if (res.status) {
          this.memberList = res.data
        }
      });
    },
    open(wjdz){
      this.wjdz = wjdz
      this.isShow = true
      this.phoneCodeShow = false
      this.phoneCode = ''
      this.verification = ''
    },
    openFile() {
      if(!this.phoneCode) {
        this.$notify({
          message: '请输入短信验证码',
          type: 'warning'
        });
        return;
      }
      window.open(this.getFileUrl(this.wjdz, false, this.phoneCode), "_blank");
    },
    // 验证码是否过期
    isCodeExist() {
      userCodeExpired().then((res) => {
        if (res.status) {
          if (res.data) { //已过期可重发
            this.getImageCode();
            this.phoneCodeShow = true;
          } else {
            this.$confirm("验证码尚未过期, 是否重新获取?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
                .then(() => {
                  this.getImageCode();
                  this.phoneCodeShow = true;
                })
                .catch(() => {
                  return;
                });
          }
        }
      });
    },
    // 获取算式验证码
    getImageCode: function() {
      var _this = this;
      getcode().then((res) => {
        if (res.status) {
          _this.imgCode.uuid = res.data.uuid;
          _this.imgCode.img = res.data.img;
        }
      });
    },
    /* 发送手机验证码*/
    sendPhoneCode: function(formDate) {
      return sendUserPhoneCode(formDate);
    },

    /*获取验证码*/
    getCode: function() {
      var _this = this
      if (!this.committingPhoneCode) {
        /*如果状态正确*/
        var formDate = {
          code: this.verification,
          uuid: this.imgCode.uuid
        }
        this.committingPhoneCode = true
        this.sendPhoneCode(formDate).then(res => {
          if (res.status) {
            _this.resetCode(60); //倒计时
            this.committingPhoneCode = false
            this.phoneCodeShow = false;
            _this.$notify({
              title: '成功',
              message: res.message,
              type: 'success'
            });
          } else {
            // this.imgCodeChange();
            _this.resetCode(5);
          }

        })
      }

    },
    //倒计时
    resetCode: function(second) {
      var timer = null;
      var _this = this;
      timer = setInterval(function() {
        second -= 1;
        if (second > 0) {
          $('.get-code').css('cursor', 'no-drop')
          $('.get-code').text(second + '秒')
        } else {
          clearInterval(timer);
          $('.get-code').css('cursor', 'pointer')
          $('.get-code').text('点击获取验证码')
          _this.committingPhoneCode = false
        }
      }, 1000);
    },
  },
  mounted() {
    this.getList()
  }

};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList queryForm">
                <el-input class=" mr-2 " placeholder="查询标题"  v-model="query.keyword" size="small" maxlength="50" >
                </el-input>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="getList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>


              </div>

            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                <tr>

                  <th style="width: 80px;">
                    <input type="checkbox" id="all" name="all" @change="checkAll" /> 序号</th>
                  <th>标题</th>
                  <th style="width: 8%;">文件类型</th>
                  <th style="width: 10%;">发送人</th>
                  <th style="width: 150px;">发送时间</th>
                  <th style="width: 8%;">文件时效性</th>
                  <th style="width: 120px;">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">

                  <td>
                    <input type="checkbox" name="infoId" :value="obj.infoId" class="checkRow"/>
                    {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                  </td>
                  <td>{{obj.wjbt}}</td>
                  <td>{{obj.wjlx}}</td>
                  <td> {{obj.fsr}}</td>
                  <td> {{obj.fssj}}</td>
                  <td :style="{color:isDateExpired(obj.yxsj)||obj.wjsxx == 0?'blue':'red'}"> {{obj.wjsxx == 0 ?'长期有效':obj.yxsj}}{{obj.wjsxx == 0 ?'':'前'}}</td>


                  <td class="tab-icon">
                        <span style="cursor: pointer"  @click='editItem(obj, false)'>下载</span>
                    &emsp;
                    <span style="cursor: pointer"  @click='editItem(obj, true)'>转发</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                             layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <!-- 添加-->
    <el-dialog id="addClass" :visible.sync="showmodal" :close-on-click-modal="false" :title="this.dialogTitle" no-close-on-backdrop title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>文件标题：</label>
              <div class=" col-sm-10 p-0 flexList">
                {{addForm.wjbt}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>简要描述：</label>
              <div class="col-sm-10  p-0">
                {{addForm.wjms}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>文件类型：</label>
              <div class="col-sm-10  p-0">
                {{addForm.wjlx}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>发布时间：</label>
              {{ addForm.fssj }}
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">时&ensp;效&ensp;性：</label>
              <div class="col-sm-10 p-0 ">
                <el-radio-group size="small" :disabled="true" v-model="addForm.wjsxx"  >
<!--                  <el-radio-button label="0">长期有效</el-radio-button>-->
                  <el-radio-button label="15">15天</el-radio-button>
                  <el-radio-button label="30">30天</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item  v-if="addForm.flag" >
            <div class="d-flex check-distri mb-3"><label>转&emsp;&emsp;发：</label>
              <div style="width: 80%;">
                <el-input v-model="addForm.yddhStr" type="textarea" maxlength="1000" style="width: 80%;" placeholder="输入手机号码，多个用英文逗号分开"></el-input>
                <el-button size="mini" style="margin-left: 10px;" @click="addUser"><i class="iconfont mr-1 el-icon-user"></i>添加用户</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>转发用户：</label>
              <div v-if="addForm.userList&&addForm.userList.length">
                <el-tag
                    v-for="(user, index) in addForm.userList"
                    :key="index"
                    class="mb-2 mr-2"
                    type="success"
                    closable
                    @close="handleUserClose(user)"
                >
                  {{ user.sjr }}
                </el-tag>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>下载文件：</label>
              <div class="col-sm-10 p-0 ">
                <div v-if="addForm.fileList && addForm.fileList.length">
                  <el-tag
                      v-for="(file, index) in addForm.fileList"
                      :key="index"
                      class="mb-2"
                      type="info"
                      title="点击下载"
                      @click="open(file.wjdz)"
                  >
                    {{ file.wjm }}
                  </el-tag>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="addForm.flag" class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
                                              @click="submit">确认转发</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>
    </el-dialog>
    <el-dialog id="imgCheck"  :visible.sync="isShow"  title="短信验证码"
               top="38vh" width="400px"
             hide-footer>
 
      <div v-if="phoneCodeShow" >
        <div class="item-box" ><span class="blue-font font-width "  >验证码
                            </span><input type="text" placeholder="请输入算式验证码" v-model="verification" class="modal-input"
        >
          <img :src="imgCode.img" class="check-img" @click="getImageCode"  style="right: 46px;top: 96px;"/>
        </div>
        <div class="w-100 mt-2">
          <button type="button" class="btn btn-info change-btn get-code" @click="getCode">
            获取验证码
          </button>
        </div>
      </div>
      <div v-else>
        <div class="item-box"><span class="blue-font font-width " @click="isCodeExist">点击获取验证码
                              </span><input type="text" placeholder="请输入短信验证码" v-model="phoneCode" class="modal-input"
                                            required>
        </div>
        <div class="w-100 mt-2">
          <button type="button" class="btn btn-info change-btn" @click="openFile">
            下载文件
          </button>
        </div>
      </div>
    </el-dialog>
  </Layout>
</template>
